import * as yup from 'yup';

export const TIME_OPTIONS = [
  {
    label: '10 minutes',
    value: '10',
  },
  {
    label: '20 minutes',
    value: '20',
  },
  {
    label: '30 minutes',
    value: '30',
  },
  {
    label: '40 minutes',
    value: '40',
  },
  {
    label: '50 minutes',
    value: '50',
  },
  {
    label: '60 minutes',
    value: '60',
  },
];

export const validationSchema = yup.object().shape({
  location: yup.string().required('Please enter a location'),
});
