import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 980px;
  height: 100%;
  max-height: 660px;
  background-color: ${({ theme }) => theme.background};
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const MapContainer = styled.div`
  flex: 1;
`;

export const TravelSearchWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px;
  width: 100%;
  max-width: 560px;
  z-index: 1;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  gap: 10px;
  height: 50px;

  & > button {
    width: 160px;
    height: 50px;
  }
`;
