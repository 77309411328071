import styled from 'styled-components';

const Container = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 110;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

export default Container;
