import togglePageScroll from '@propertypal/web-app/src/utils/togglePageScroll';
import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import Container from './ModalWrapper.style';

interface Props {
  scrollSelector?: string;
  ariaLabel?: string;
  show: boolean;
  onClose: () => void;
  children: ReactNode;
}

interface State {
  root: HTMLElement | null;
}

class ModalWrapper extends React.Component<Props, State> {
  state: State = {
    root: null,
  };

  componentDidMount() {
    this.setState({
      root: document.getElementById('modal-root'),
    });

    window.addEventListener('keydown', this.handleKeyPress);
  }

  shouldComponentUpdate(nextProps: Readonly<Props>) {
    if (this.props.show && !nextProps.show && nextProps.scrollSelector) {
      togglePageScroll(nextProps.scrollSelector, true);
    }

    return true;
  }

  componentDidUpdate() {
    if (this.props.show && this.props.scrollSelector) {
      togglePageScroll(this.props.scrollSelector, false);
    }
  }

  componentWillUnmount() {
    if (this.props.scrollSelector) {
      togglePageScroll(this.props.scrollSelector, true);
    }

    window.removeEventListener('keydown', this.handleKeyPress);
  }

  handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Escape' && this.props.show) {
      this.props.onClose();
    }
  };

  render() {
    const Content = (
      <Container id="modalWrapper" role="dialog" aria-label={this.props.ariaLabel} data-testid="modal-container">
        {this.props.children}
      </Container>
    );

    if (this.props.show && this.state.root) {
      return ReactDOM.createPortal(Content, this.state.root);
    }

    return null;
  }
}

export default ModalWrapper;
