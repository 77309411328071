import React, { Component, CSSProperties } from 'react';

export interface PathPoint {
  x: number;
  y: number;
}

interface Props {
  strokeWidth: number;
  strokeColor: string;
  style: CSSProperties;
  onStroke: (path: PathPoint[]) => void;
}

class DrawCanvas extends Component<Props> {
  canvasRef: HTMLCanvasElement | null = null;

  ctx: CanvasRenderingContext2D | null = null;

  drawing: boolean = false;

  lastX: number | null = null;

  lastY: number | null = null;

  path: PathPoint[] = [];

  componentDidMount() {
    if (this.canvasRef) {
      this.ctx = this.canvasRef.getContext('2d');
      this.canvasRef.addEventListener('resize', this.handleResize);

      this.canvasRef.addEventListener('mouseup', this.endDrawing);
      this.canvasRef.addEventListener('mouseout', this.endDrawing);
      this.canvasRef.addEventListener('mousedown', this.startDrawing);
      this.canvasRef.addEventListener('mousemove', this.handleMouseMove);

      this.canvasRef.addEventListener('touchend', this.endDrawing);
      this.canvasRef.addEventListener('touchcancel', this.endDrawing);
      this.canvasRef.addEventListener('touchstart', this.startDrawing);
      this.canvasRef.addEventListener('touchmove', this.handleTouchMove);

      this.handleResize();
    }
  }

  componentWillUnmount() {
    if (this.canvasRef) {
      this.canvasRef.removeEventListener('resize', this.handleResize);

      this.canvasRef.removeEventListener('mouseup', this.endDrawing);
      this.canvasRef.removeEventListener('mouseout', this.endDrawing);
      this.canvasRef.removeEventListener('mousedown', this.startDrawing);
      this.canvasRef.removeEventListener('mousemove', this.handleMouseMove);

      this.canvasRef.removeEventListener('touchend', this.endDrawing);
      this.canvasRef.removeEventListener('touchcancel', this.endDrawing);
      this.canvasRef.removeEventListener('touchstart', this.startDrawing);
      this.canvasRef.removeEventListener('touchmove', this.handleTouchMove);
    }
  }

  handleResize = () => {
    if (this.ctx && this.canvasRef) {
      this.ctx.canvas.width = this.canvasRef.getBoundingClientRect().width * 2;
      this.ctx.canvas.height = this.canvasRef.getBoundingClientRect().height * 2;
      this.ctx.scale(2, 2);
    }
  };

  startDrawing = () => {
    this.drawing = true;
  };

  endDrawing = () => {
    if (this.drawing) {
      this.props.onStroke([...this.path]);
      this.path = [];
      this.drawing = false;
    }
  };

  handleTouchMove = (event: TouchEvent) => {
    // Prevent touch move scrolling the page whilst drawing
    event.preventDefault();

    if (this.drawing && this.canvasRef) {
      const x = event.touches[0].clientX - this.canvasRef.getBoundingClientRect().left;
      const y = event.touches[0].clientY - this.canvasRef.getBoundingClientRect().top;

      this.drawLine(x, y);
    }
  };

  handleMouseMove = (event: MouseEvent) => {
    if (this.drawing && this.canvasRef) {
      const x = event.clientX - this.canvasRef.getBoundingClientRect().left;
      const y = event.clientY - this.canvasRef.getBoundingClientRect().top;

      this.drawLine(x, y);
    }
  };

  drawLine = (x: number, y: number) => {
    this.path.push({ x, y });

    if (this.lastX === null || this.lastY === null) {
      this.lastX = x;
      this.lastY = y;
    }

    if (this.ctx) {
      this.ctx.beginPath();
      this.ctx.moveTo(this.lastX, this.lastY);
      this.ctx.lineTo(x, y);
      this.ctx.lineCap = 'round';
      this.ctx.lineJoin = 'round';
      this.ctx.strokeStyle = this.props.strokeColor;
      this.ctx.lineWidth = this.props.strokeWidth;
      this.ctx.stroke();
      this.ctx.closePath();
    }

    this.lastX = x;
    this.lastY = y;
  };

  render() {
    return (
      <canvas
        data-testid="drawCanvas"
        ref={(el) => {
          this.canvasRef = el;
        }}
        style={this.props.style}
      />
    );
  }
}

export default DrawCanvas;
