import { faBicycle } from '@fortawesome/pro-regular-svg-icons/faBicycle';
import { faCarSide } from '@fortawesome/pro-regular-svg-icons/faCarSide';
import { faClock } from '@fortawesome/pro-regular-svg-icons/faClock';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { faWalking } from '@fortawesome/pro-regular-svg-icons/faWalking';
import { TIME_OPTIONS, validationSchema } from '@propertypal/shared/src/constants/map';
import useDebounce from '@propertypal/shared/src/hooks/useDebounce';
import { useFormik } from 'formik';
import React, { CSSProperties, FunctionComponent, useEffect } from 'react';
import { useTheme } from 'styled-components';
import Button from '../buttons/Button';
import FontAwesomeIcon from '../icons/FontAwesomeIcon';
import SelectInput from '../inputs/SelectInput';
import TextInput from '../inputs/TextInput';
import { Row } from '../layout';
import { MidHeading } from '../typography';
import { Container, LowerRow, TravelOption } from './TravelSearchBox.style';

interface Props {
  containerStyle?: CSSProperties;
  title?: string;
  loading?: boolean;
  onUpdate: (location: string, time: string, mode: string) => void;
  onSubmit?: () => void;
  error?: string;
  onClose?: () => void;
}

const UPDATE_DELAY = process?.env?.JEST_WORKER_ID ? 0 : 500;

const TravelSearchBox: FunctionComponent<Props> = (props) => {
  const theme = useTheme();

  const formik = useFormik({
    initialValues: {
      location: '',
      mode: 'drive',
      time: '',
    },
    validationSchema,
    onSubmit: () => {
      if (props.onSubmit) {
        props.onSubmit();
      }
    },
  });

  const handleModeChange = (mode: string) => () => {
    formik.handleChange('mode')(mode);
  };

  const handleUpdateDebounce = useDebounce(props.onUpdate, UPDATE_DELAY, { leading: false, trailing: true }, []);

  useEffect(() => {
    if (formik.values.location && formik.values.time) {
      handleUpdateDebounce(formik.values.location, formik.values.time, formik.values.mode);
    }
  }, [formik.values.location, formik.values.time, formik.values.mode]);

  return (
    <Container style={props.containerStyle} onSubmit={formik.handleSubmit}>
      {props.title && (
        <Row style={{ justifyContent: 'space-between' }}>
          <MidHeading fontSize={14}>{props.title}</MidHeading>

          {props.onClose && (
            <button type="button" onClick={props.onClose}>
              <FontAwesomeIcon icon={faTimes} color={theme.textDark} style={{ fontSize: 20 }} />
            </button>
          )}
        </Row>
      )}

      <TextInput
        rounded
        value={formik.values.location}
        onValueChange={formik.handleChange('location')}
        name="travelSearchText"
        placeholder="Enter a postcode, place name or address"
        error={(formik.touched.location && formik.errors.location) || props.error}
        testID="travelSearchBoxInput"
      />

      <LowerRow>
        <Row>
          <TravelOption
            title="Driving"
            aria-label="Car"
            active={formik.values.mode === 'drive'}
            onClick={handleModeChange('drive')}
            type="button"
          >
            <FontAwesomeIcon icon={faCarSide} color={theme.white} style={{ fontSize: 18 }} />
          </TravelOption>

          <TravelOption
            title="Walking"
            aria-label="Walk"
            active={formik.values.mode === 'walk'}
            onClick={handleModeChange('walk')}
            type="button"
          >
            <FontAwesomeIcon icon={faWalking} color={theme.white} style={{ fontSize: 18 }} />
          </TravelOption>

          <TravelOption
            title="Cycling"
            aria-label="Bicycle"
            active={formik.values.mode === 'bicycle'}
            onClick={handleModeChange('bicycle')}
            type="button"
          >
            <FontAwesomeIcon icon={faBicycle} color={theme.white} style={{ fontSize: 18 }} />
          </TravelOption>
        </Row>

        <SelectInput
          inputStyle={{ height: 42 }}
          icon={faClock}
          ariaLabel="Travel time"
          placeholder="Select a travel time"
          id="travelTime"
          options={TIME_OPTIONS}
          value={formik.values.time}
          onChange={formik.handleChange('time')}
          testID="travelTimeSelect"
        />

        {props.onSubmit && (
          <Button loading={props.loading} type="submit">
            Add to Search
          </Button>
        )}
      </LowerRow>
    </Container>
  );
};

export default TravelSearchBox;
