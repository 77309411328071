import { mobileXXLargeMax } from '@propertypal/shared/src/constants/mediaQueries';
import styled from 'styled-components';
import { Container as Button } from '../buttons/Button.style';
import { Input } from '../inputs/TextInput.style';

export const Container = styled.form`
  background-color: ${({ theme }) => theme.background};
  border-radius: 4px;
  width: 100%;
  padding: 10px;

  ${Input} {
    border: 1px solid ${({ theme }) => theme.keyline};
    height: 46px;
  }
`;

export const LowerRow = styled.div`
  display: flex;
  margin-top: 5px;

  ${Button} {
    max-width: 120px;
    height: 42px;
    margin-left: 5px;
  }

  @media (${mobileXXLargeMax}) {
    flex-direction: column;

    ${Button} {
      margin-top: 5px;
      max-width: none;
      margin-left: 0;
    }
  }
`;

export const TravelOption = styled.button<{ active?: boolean }>`
  width: 42px;
  height: 42px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.backgroundMid};
  margin-right: 5px;

  @media (${mobileXXLargeMax}) {
    flex: 1;

    &:last-child {
      margin-right: 0;
      margin-bottom: 5px;
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.textLight};
  }

  ${({ theme, active }) =>
    active &&
    `
    background-color: ${theme.primary};
    
     &:hover {
      background-color: ${theme.primary};
    }
  `}
`;
